import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TabBar from "../../components/TabBar";
import { servicesFields } from "../../metadata";
import Grid from "@material-ui/core/Grid";
import { withPrefix, Link } from "gatsby";
import classnames from 'classnames';
import Layout from '../../components/layout';

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit * 4,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 22,
      marginRight: theme.spacing.unit * 22,
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 5
    }
  },
  subheading: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem"
    }
  },
  article: {
    ...theme.typography.article,
    textTransform: "uppercase",
    paddingTop: theme.spacing.unit * 3
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    listStyleType: "disc",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  build: {
    ...theme.typography.paragraph,
    lineHeight: "1.5",
    fontSize: "16px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    }
  },
  buildItem: {
    width: "250px",
    marginBottom: "30px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  container: { marginTop: "44px", marginBottom: "44px" },
  box: {
    border: "1px solid #959595",
    padding: "30px 20px",
    marginTop: theme.spacing.unit * 7
  },
  boxHeading: {
    ...theme.typography.subheading,
    fontSize: "30px",
    color: "rgba(37, 54, 156, 255)",
    fontWeight: "400",
    textTransform: "uppercase"
  },
  boxText: { paddingLeft: "10px" }
});

const buildItems = [
  {
    icon: withPrefix("icons/mobile/tracking.svg"),
    text: 'Connected location and microlocation tracking app features'
  },  {
    icon: withPrefix("icons/mobile/ecommerce-apps.svg"),
    text: 'Consumer B2C e-commerce apps'
  },  {
    icon: withPrefix("icons/mobile/enterprise-systems.svg"),
    text: 'Enterprise systems integrated B2B apps'
  },  {
    icon: withPrefix("icons/mobile/learning.svg"),
    text: 'Learning, educational and testing platforms'
  },  {
    icon:  withPrefix("icons/mobile/kiosk.svg"),
    text: 'Kiosk applications'
  },  {
    icon: withPrefix("icons/mobile/games.svg"),
    text: 'Games'
  },
];

const Mobile = ({ classes, location }) => (
  <Layout location={location}>
    <React.Fragment>
    <TabBar fields={servicesFields} />
    <div className={classes.root}>
      <div className={classes.subheading}>
        Custom MOBILE APPs.
      </div>
      <div className={classes.paragraph}>
        Primoko has been providing custom mobile app development services
        almost since Apple launched app store back in 2007! We’ve since
        expanded our capabilities to include Android and cross-platform
        development.
      </div>
      <div className={classes.paragraph}>
        We can create complex yet usable applications that will connect
        you with your market in new and future-forward ways. We will work
        closely with you from concept, planning, and design, to
        development, deployment, and support.
      </div>
      <Grid container justify="space-between">
        <Grid item sm={12} md={7}>
          <div className={classnames(classes.subheading, classes.container)}>
            We’ve BUILT…
          </div>
          <ul className={classes.build}>
            {buildItems.map(({icon, text}, index) =>
              <li className={classes.buildItem} key={index}>
                <img
                  src={icon}
                  width="30"
                  height="30"
                />
                <span className={classes.paragraph}>{text}</span>
              </li>
            )}
          </ul>
        </Grid>
        <Grid item sm={12} md={4}>
          <div className={classes.box}>
            <div className={classes.boxHeading}>We speak mobile</div>
            <div className={classes.paragraph}>
              Recent projects have used:
            </div>
            <Grid container alignItems="center" justify="space-between">
              <Grid item xs={12} className={classes.paragraph}>
                <img
                  src={withPrefix("icons/ios.png")}
                  width="16"
                  height="17"
                />
                <span className={classes.boxText}>
                  iOS, including Apple Watch
                </span>
              </Grid>
              <Grid item xs={12} className={classes.paragraph}>
                <img
                  src={withPrefix("icons/android.png")}
                  width="16"
                  height="17"
                />
                <span className={classes.boxText}>
                  Android, including Wear OS
                </span>
              </Grid>
              <Grid item xs={12} className={classes.paragraph}>
                <img
                  src={withPrefix("icons/react.png")}
                  width="16"
                  height="17"
                />
                <span className={classes.boxText}>
                  React Native / Cross-platform
                </span>
              </Grid>
            </Grid>
            <div className={classes.paragraph}>
              Need a different technology?
              <br/>
              <Link to="/letstalk">Contact us</Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  </React.Fragment>
  </Layout>
);

export default withStyles(styles)(Mobile);
