import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withPrefix, graphql } from 'gatsby';
import Mobile from '../../containers/services/Mobile';

const styles = (theme) => ({
	container: {
    backgroundColor: "pink",
    
    [theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing.unit * 4,
			paddingRight: theme.spacing.unit * 4,
			paddingTop: theme.spacing.unit * 2,
			paddingBottom: theme.spacing.unit * 2
		  },
		  [theme.breakpoints.up("sm")]: {
			paddingLeft: theme.spacing.unit * 22,
			paddingRight: theme.spacing.unit * 22,
			paddingTop: theme.spacing.unit * 5,
			paddingBottom: theme.spacing.unit * 5
		  }
	},
	title: {
		[theme.breakpoints.down('sm')]: {
			...theme.typography.mtitle
		},
		[theme.breakpoints.up('sm')]: {
			...theme.typography.title
		}
	},
	paragraph: {
		[theme.breakpoints.down('sm')]: {
			...theme.typography.paragraph
		},
		[theme.breakpoints.up('sm')]: {
			...theme.typography.paragraph
		}
	},
	space: {
		marginTop: theme.spacing.unit * 4
	},
	list: {
		paddingLeft: theme.spacing.unit * 2,
		listStyle: 'disc'
	},
	subTitle: {
		[theme.breakpoints.down('sm')]: {
			...theme.typography.mtitle
		},
		[theme.breakpoints.up('sm')]: {
			...theme.typography.title
		},
		width: 'fit-content',
		borderBottom: '6px solid #6f73ff',
		paddingBottom: '8px'
	},
	outerBox: {
		width: '281px',
		height: '569px'
	},
	subparagraph: {
		borderBottom: '6px solid #6f73ff',
		paddingBottom: '6px'
	},
	mobile: {
		backgroundImage: `url(${withPrefix('/img/we-know-mobile.png')})`,
		position: 'relative',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'auto',
		margin: 'auto',
		[theme.breakpoints.up('xs')]: {
			height: '280px',
			width: '286px'
		},
		[theme.breakpoints.up('md')]: {
			height: '569px',
			width: '286px'
		}
	}
});

export default withStyles(styles)(
	class ServicesMobilePagePreviewTemplate extends React.Component {
		render() {
			const { data, classes } = this.props;
			const { frontmatter } = data.markdownRemark;
			const heading = frontmatter.heading.split('—');
			const styled = {
				backgroundImage: "linear-gradient(to right ,#407bdf, #3a70cb)",
				marginTop: "30px"
			};
			return (
				<Mobile />
			);
		}
	}
);

export const pageQuery = graphql`
	query ServicesMobilePage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				heading
				description
				rightList
				leftList
				others
				testimonials {
					author
					authorRole
					quote
				}
			}
		}
	}
`;
